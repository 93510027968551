import { localizedStrings } from "../../../constants/localizedStrings"
import { getPricePerLicense } from "../../../utils/pricePerLicense";

export const CHANGE_PRICE_TO_US = 'change_price_to_US'
export const CHANGE_PRICE_TO_BR = 'change_price_to_BR'
export const APPLY_LICENSES_DISCOUNT = 'apply_licenses_discount'


const INITIAL_STATE = {
    pricesPerUser: [
        {
            users: "5",
            price: "54.90"
        },
        {
            users: "41",
            price: "44.90"
        },
    ],
    planTypes: [
        {
            name: localizedStrings.bankSlip,
            value: "boleto",
        },
        {
            name: localizedStrings.monthlyCreditCard,
            value: "card",
        }

    ],
    prices: [
        {
            value: {
                plan: "quarterly",
                type: "boleto"
            },
            name: localizedStrings.quarterly,
            rawPrice: "54.90",
            price: "54.90",
        },
        {
            value: {
                plan: "semester",
                type: "boleto"
            },
            name: localizedStrings.semester,
            rawPrice: "54.90",
            price: "49.41",
            discount: "10%",
        },
        {
            value: {
                plan: "annual",
                type: "boleto"
            },
            name: localizedStrings.annual,
            rawPrice: "54.90",
            price: "46.67",
            discount: "15%",
        },
        {
            value: {
                plan: "monthly",
                type: "card"
            },
            name: localizedStrings.monthly,
            rawPrice: "54.90",
            price: "54.90",
        },
    ],
};


export default function subscriptions(state = INITIAL_STATE, action) {
    const actionTypes = {
        apply_licenses_discount() {
            return {
                ...state,
                prices: state.prices.map(priceObj => {
                    const {
                        price,
                        rawPrice
                    } = getPricePerLicense({
                        licenses: Number(action.payload.licenses),
                        plan: priceObj?.value?.plan,
                        type: priceObj?.value?.type,
                    });
                    return {
                        ...priceObj,
                        rawPrice,
                        price,
                    }
                })
            }
        },
        change_price_to_US() {
            return {
                ...state,
                prices: state.prices.map(price => {
                    if (price.value.type === "card") {
                        return { ...price, price: "22.90", rawPrice: "26.90" }
                    }
                    return { ...price, }
                })
            }
        },
        change_price_to_BR() {
            return {
                ...state,
                prices: state.prices.map(price => {
                    if (price.value.type === "card") {
                        return { ...price, price: "54.90", rawPrice: "54.90" }
                    }
                    return { ...price, }
                })
            }
        }
    }
    return actionTypes?.[action.type]?.() ?? state;
}
