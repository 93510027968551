import { getSystemUrlByEnv, getApiUrlByEnv, getSystemApiUrlByEnv } from "../utils/envUrl";

export const API_URL = "https://" + getApiUrlByEnv({ url: window.location.hostname })
export const SYSTEM_URL = "https://" + getSystemUrlByEnv({ url: window.location.hostname });
export const WEB_API_URL = "https://" + getSystemApiUrlByEnv({ url: window.location.hostname });
export const LOGIN_URL = SYSTEM_URL + "/#/login";
export const APP_PATH = "/subscription"
export const AUTH_PATH = APP_PATH + "/auth"
export const MAXIMUM_LICENSES = 500;
export const MINIMUM_LICENSES = 5;
export const MINIMUM_LICENSES_ADDITIVE_NUMBER = 1;
export const EMAIL_MAX_COUNT = 3;
export const EMAILS_DEFAULT_LENGTH = 150;

