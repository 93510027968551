import React, { memo, useMemo } from 'react';
import { InfoCardsStyle } from './style'
import { InfoCard } from '../../components'
import { useSelector } from 'react-redux';
function InfoCards({ isNewSubscription = false }) {
    const {
        activeSubscription: {
            pricing_schema
        }
    } = useSelector(state => state.users)

    const defaultPricingSchema = [
        {
            start_quantity: 5,
            numberOfUsers: 5,
            pricePerEmployeer: 54.90,
        },
        {
            start_quantity: 41,
            numberOfUsers: 41,
            pricePerEmployeer: 44.90
        }
    ];

    const has_pricing_schema = useMemo(() => pricing_schema && pricing_schema.length > 0, [pricing_schema]);

    const active_pricing_schema = useMemo(() => {
        if(has_pricing_schema === true && isNewSubscription === false) {
            return pricing_schema;
        };

        return defaultPricingSchema;
    }, [has_pricing_schema]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InfoCardsStyle cards_length={active_pricing_schema?.length} >
                {
                    active_pricing_schema.map((price, index) =>
                        <InfoCard
                            key={index}
                            price_schema_index={index}
                            start_quantity={price.start_quantity}
                            pricing_schema={active_pricing_schema}
                            {...price}
                        />
                    )
                }
            </InfoCardsStyle>
        </div>
    );
}
export default memo(InfoCards)
